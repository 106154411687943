
import styled from "styled-components";
// import blackLineImg from "../../images/black-line.png";
// import logoImg from "../../images/logo.png";
// import circleBgImg from "../../images/circle-bg.png";
// import blueCardImg from "../../images/blue-card.png";


const blackLineImg = "https://dashboard.tap1ce.uk/media/black-line.png";
const logoImg = "https://dashboard.tap1ce.uk/media/logo.png";
const circleBgImg =  "https://dashboard.tap1ce.uk/media/circle-bg.png";
const blueCardImg = "https://dashboard.tap1ce.uk/media/blue-card.png";


export const HomeContainer = styled.div`

@keyframes scale {
  from {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
  }
  to {
      transform: scale(1.08);
      -webkit-transform: scale(1.08);
  }
}

@keyframes slideInleft{
0% {    
  right: -10%;
}
100% {  
  right: 10%; 
} 
}

.left-side,
.right-side {
    position: relative;
}

.left-side img,
.right-side img{
   
}

.left-side img:nth-child(1) {
    position: absolute;
    right: -150%;
    top: -30%;
    height: 120px;
    object-fit: contain;
     animation: scale 1s alternate infinite;
}

.left-side img:nth-child(2) {
    position: absolute;
    right: 0;
    height: 130px;
    object-fit: contain;
    animation: scale 1.5s alternate infinite; 
}

.left-side img:nth-child(3) {
    position: absolute;
    bottom: 5%;
    right: 0;
    height: 130px;
    object-fit: contain;
      animation: scale 2s alternate infinite;
}

.google {
 
  height:  110px;
}

.right-side img:nth-child(1) {
    position: absolute;
    left: -50%;
    top: -17%;
    height:  120px;
    object-fit: contain;
     animation: scale 1.5s alternate infinite;
}

.right-side img:nth-child(2) {
    position: absolute;
        left: 30%;
    bottom: 32%;
    height: 90px;
    object-fit: contain;
   animation: scale 2s alternate infinite;
}

.right-side img:nth-child(3) {
    position: absolute;
   left: 30%;
    bottom: -10%;
    height: 130px;
    object-fit: contain;
    animation: scale 1s alternate infinite;
}

.middle-sec{
    z-index: 1;
}


.scale {
    animation: scale 2.5s alternate infinite;
    display: block;
    margin: 0 auto;
}



img.slide-card {
    position: absolute;
    height:180px;
    top: 10%;
    object-fit: contain;
    right: 0;
     animation:slideInleft 2.5s alternate infinite;
}
@media (max-width: 767px){
 
  
  .hero-2{
      height: auto;
  }
  
  
  .right-side,
  .left-side{
  display: flex;
  justify-content: center;
  }
  
  .right-side img,
  .left-side img{
      position: unset !important;
      height:80px !important;
  }
  
  .scale{
      height: 80px;
      object-fit: contain;
      margin-bottom: 30px !important;
  }
  
  img.slide-card{
      height: 110px;
      top:25%;
  }
  
  
  @keyframes slideInleft{
    0% {    
      right: 0;
    }
    100% {  
      right: 30%; 
    } 
  }
  
  .middle-sec{
  display: flex;
      justify-content: center;
      flex-wrap: wrap;
  }
  
  .middle-sec img:nth-child(1){
      width: 85%;
      margin-top: 80px;
  }
  
  .right-side img:nth-child(2){
    height: 80px !important;
  }
  }
  
  
  @media only screen and (min-width:768px) and (max-width:991px){
    .col-6{
  width: 100%;
  }
  
  @keyframes slideInleft{
    0% {    
      right: 0;
    }
    100% {  
      right: 40%; 
    }
  }
  
  .right-side img:nth-child(1){
    top:-10%;
  }
  
  .left-side img:nth-child(1){
    right: -110%;
      top: -12%;
      height: 120px;
  }
  
  .right-side img:nth-child(2){
    left: -30%;
    height: 100px;
  }
  
  .right-side img:nth-child(3){
    left: -30%;
    height: 130px;
  }
  
  .left-side img:nth-child(3){
    right: -35%;
    height: 110px;
  }
  
  .scale{
  height: 130px;	
  }
  
  .left-side img:nth-child(2){
    right: -15%;
      height: 130px;
  }
  
  }
  
  
  @media only screen and (min-width:992px) and (max-width:1200px){
  
      .right-side img:nth-child(2) {
         left: -100%;
      bottom: 32%;
      height: 70px !important;
  }
  
  .right-side img:nth-child(1){
      left: -120%;
  }
  
  .left-side img:nth-child(1){
      top:-25%;
  }
  
  .right-side img:nth-child(3){
         left: -100%;
      bottom: 0;
  }
  
  
  .left-side img, .right-side img{
      height: 100px !important;
  
  }
  
  
  @keyframes slideInleft{
    0% {    
      right: 5%;
    }
    100% {  
      right: 15%; 
    }
  }
  
  img.slide-card {
      height: 120px;
      bottom: unset;
  }
  
  .scale{
          height: 100px;
          object-fit: cover;
  }
  
  
  
  }
  
  @media only screen and (min-width:1201px) and (max-width:1400px){
      .middle-sec img:nth-child(1){
          height: 400px;
          object-fit: contain;
      }
  
      .left-side img:nth-child(1){
          top:-20%;
         
      }
  
      .left-side img,.right-side img{
           height: 120px !important;
      }
  
    .right-side img:nth-child(2){
       left:-30%;
           height: 100px !important;
    }
  
      .right-side img:nth-child(3){
        left:-30%;
      }
  
      .scale{
          height: 100px;
      }
  
      @keyframes slideInleft{
    0% {    
      right: 0%;
    }
    100% {  
      right: 10%; 
    }
  }
  }
  
  
  @media only screen and (min-width:1401px) and (max-width:1600px){
      .middle-sec img:nth-child(1){
          height: 480px;
          object-fit: contain;
      }
  
      .left-side img:nth-child(1){
          right: -100%;
          top:-20%;
  
      }
  }
  
  
  
  @media (min-width: 1200px) {
      .container {
          max-width: 1140px;
      }
  }
  
  @media (min-width: 1950px){
          @keyframes slideInleft{
    0% {    
      right: 0%;
    }
    100% {  
      right: 15%; 
    }
  }
}










@font-face {
  font-family: JejuGothic;
  src: url(fonts/JejuGothic-Regular.ttf);
  font-weight: regular;
}

@keyframes blink {
  0% {
      background-color: #ccc;
  }
  49% {
      background-color: #ccc;
  }
  50% {
      background-color: transparent;
  }
  99% {
      background-color: transparent;
  }
  100% {
      background-color: #ccc;
  }
}


.hero {
  display: block;
  align-items: center;
  position: relative;
  margin-left:20px;
}

.hero .title {
  font-family: "JejuGothic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #0a63a4;
  text-transform: uppercase;
}

.hero .typed-text {
  background: #7cc1d0;
}

.hero p.title span.cursor {
  animation: blink 1s infinite;
  display: inline-block;
  background-color: #ccc;
  margin-left: 0.1rem;
  width: 3px;
}



#object {
  position: absolute;
  transform: translateX(0px);
  transition: all 1s;
}

.moving_object{
  max-width: 440px;
  border-radius: 15px;
}


.bg-white{
  background: #FFFFFF;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.06);
}

.main-title{
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 55px;
    /* or 154% */
    text-transform: uppercase;
}

.main-title span{
  background: #17556D;
  color:#fff;
  border-radius: 3px;
  padding-left:10px;
  padding-right:10px;
  padding-top:0px;
  padding-bottom:0px;
}

.main-title-light{
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 55px;
  /* or 154% */
  text-transform: uppercase;
}

.main-title-light span{
background: rgba(23, 85, 109, 0.05);
color:#17556D;
border-radius: 3px;
padding-left:10px;
padding-right:10px;
padding-top:0px;
padding-bottom:0px;
}


.black-title{
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  text-transform: uppercase;
  text-align: center;
  color: #1A1A1A;
  background-position: 50% 1.4em;
  background-size: 200px 2px;
  background-repeat: no-repeat;
  background-image:url(${blackLineImg});
}


.logo-bg-wrap {
  position: relative;
}

.logo-bg-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 10%;
  top: 0;
  width: 75%;
  height: 100%;
  opacity: 0.05;
  background-image:url(${logoImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-bg-content {
  position: relative;
}

.logo-bg-content a {
display: flex;
align-items: center;
justify-content: center;
}

.logo-bg-content a i.video {
  position:absolute;
  height: 50px;
  width: 50px;
  background: #ffffff;
  display: inline-block;
  font-size: 25px;
  color: #17556D;
  text-align: center;
  line-height: 50px;
  border-radius: 100%;
}
}


.circle-bg-wrap {
  position: relative;
  padding:50px;
}

.circle-bg-wrap:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 15%;
    top: 25%;
    width: 50%;
    height: 50%;
    background-image:url(${circleBgImg});
    background-repeat: no-repeat;
    background-size: contain;

}

.circle-bg-content {
  position: relative;
}

.circle-bg-content .title{
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 45px;
  color: #1A1A1A;
}

.circle-bg-content p{
  color: #1A1A1A;
  font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 30px;
}

.marquee-title{
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  text-align: center;
  line-height: 45px;
  color: #1A1A1A;
}

.marquee-sub-title{
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: #17556D;
}

.marquee {
   display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.marquee-wrap {
  width:100%;
  position:absolute;
  overflow: hidden;
  margin-left: -20px;
}

.marquee-content{
  display:flex;
  list-style:none;
  animation: scrolling 30s linear infinite;
}

.marquee-content-forward{
  display:flex;
  list-style:none;
  animation: scrolling-reverse 30s linear infinite;
}

.marquee-item{
  height:150px;
  flex-shrink:0;
  margin:10px;
}

.marquee-image{
  height:100%;
  width:100%;
}

@keyframes scrolling {
  0% { transform: translateX(0); }
  100% { transform: translatex(-5800px); }
}

@keyframes scrolling-reverse {
  0% { transform: translateX(-5800px); }
  100% { transform: translatex(0); }
}

.marquee-content-forward{
  display:flex;
  list-style:none;
  marquee-direction: forward;
}

.step-content{

}

.step-content .title{
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: #000000;
  margin-top: -40px;
}

.step-content .sub-title{
  text-align: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    color: #000000;
    margin-top: -40px;
    
}

.step-content .step{
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 80px;
  color: #000000;
  opacity:0.05;
}

.step-content .mt-n20{
  margin-top:-20%;
}


.slider-section{

}

.slick-center {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  transform: scale(1.25);    
}

.slick-list { 
  padding:45px 60px !important;
}

.slick-arrow{
  display: block;
  position: absolute;
  top: 50%;
  height: 40px;
  width: 40px;
  transform: translateY(-50%); 
}

.slick-prev:before, .slick-next:before {
  color: #17556D;
  font-size: 50px;
}

.slick-next{
    right: 25px;
}

.slick-prev{
    left: 25px;
}

.slick-dots li.slick-active button:before {
  color: #17556D !important;
}


.slider-section .title{
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  text-align: center;
  line-height: 45px;
  color: #1A1A1A;
}

.slider-section .sub-title{
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: #17556D;
}




@media only screen and (max-width: 600px) {

  .section {
    padding: 50px 0;
  }

  .black-title {
    font-size: 30px;
    background-position: 50% 1.5em;
  }
  .logo-bg-wrap:before{
    width: 100%;
    height: 100%;
    top:unset;
    left: 0%;
    bottom: -45%;
  }

  .circle-bg-wrap {
    position: relative;
    padding: 20px;
  }

  .circle-bg-content .title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    color: #1A1A1A;
  }

  .circle-bg-content p {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
  }

  .marquee-wrap {
    width:100%;
    overflow: hidden;
  }

  .marquee-center-image{
    max-width: 60%;
  }

  .step-content .title {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    color: #000000;
    margin-top: 40px;
}

.image-content img {
  max-width: 100%;
  padding: 20px;
}

.step-content .image-content img {
  max-width: 100%;
  padding: 20px;
}

.step-content .image-content{
  margin-top: 50px;
}

.step-content .step {
  text-align: left!important;
}

.step-content .sub-title {
  text-align: left!important;
  text-align: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  color: #000000;
  margin-top: -40px;
}


.main-gradient-banner::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    height: 200%;
    border-radius: 0%;
    background-image: linear-gradient(
45deg
, #0e1033 0%, #0e1033 20%, #faaf43 50%);
}



.bg-1 {
  background: url(https://dashboard.tap1ce.uk/media/video-banner.png) fixed no-repeat;
  background-size: cover;
}


/* Inspired from:
http://stackoverflow.com/questions/25573562/wrapping-a-youtube-video-in-a-static-image-frame-and-maintain-responsive-resiz */
/* div.mobile-wrapper {
    position: relative;
    padding-top: 25px;
    padding-bottom: 67.5%;
    height: 0;
}
div.mobile-wrapper iframe {
    box-sizing: border-box;
    background: url(https://juliamenndez.com/wp-content/uploads/mobile-frame.png) center center no-repeat;
    background-size: contain;
    padding: 7% 38.4% 12.8%;;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

div.mobile-wrapper video {
    box-sizing: border-box;
    background: url(https://dashboard.tap1ce.uk/media/mobile-frame.png) center center no-repeat;
    background-size: contain;
    padding: 7% 38.4% 12.8%;;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.mobile-phone {
    margin: auto;
    margin-top: 170px;
    padding: 10px 10px 30px;
    width: 350px;
    height: 600px;
    box-shadow: 0 0 20px #e2e2e2;
    border-radius: 30px;
    background: url(https://dashboard.tap1ce.uk/media/mobile-frame.png) center center no-repeat;
    background-size: contain;
  }
  
  .screen {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    border-radius: 30px;
    overflow-y: auto;
    overflow: hidden;
    
  }
  .screen video{
    width: inherit;
    height: fit-content;
}

.s1{
   
    width: 100%;
    height: 100%;
    border-radius: 30px;
    overflow-y: auto;
    overflow: hidden;
}

.s1 video{
    width: inherit;
    height: fit-content;
}
  
  .brove {
    width: 150px;
    height: 20px;
    background: white;
    position: absolute;
    margin: 0 100px;
    border-radius: 0 0 20px 20px;
  }
  
  .speaker {
    width: 60px;
    height: 5px;
    background: #d2d2d2;
    display: block;
    margin: auto;
    margin-top: 5px;
    border-radius: 20px;
  }
 */



  .frame {
    position: relative;
    text-align: center;
  }
  
  .frame-minimal-phone {
    border-radius: 24px;
    max-width: 360px;
    margin: 0 auto;
    padding: 8px;
    padding-top: 20px;
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    box-shadow: 0px 8px 16px 0px rgba(18, 1, 64, 0.1);
  }
  .frame-minimal-phone::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 50%;
    margin-left: -4px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: rgba(127, 127, 127, 0.25);
  }
  .frame-minimal-phone img {
    border-radius: 16px;
    max-width: 100%;
  }

  .frame-minimal-phone video {
    border-radius: 16px;
    max-width: 100%;
  }



  .mobile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: perspective 0.5s ease-in-out;
    perspective: 300px;

  }
  
  .mobile-container:hover {
    perspective: 800px;
  }
  

/*   .card-container {
    position:absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: perspective 0.5s ease-in-out;
    perspective: 300px;

  }
  
  .card-container:hover {
    perspective: 800px;
  } */

  .phone {
    width: 264px;
    height: 533px;
    padding: 10px;
    border-radius: 36px;
    background: #fff;
    box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%), inset 0 -2px 6px 0 rgb(10 37 64 / 55%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
    -webkit-transform: rotateX(2deg) rotateY(-3deg);
    transform: rotateX(2deg) rotateY(-3deg);
  }
  
  .screen {
    position: relative;
    height: 100%;
    border-radius: 34px;
    -webkit-mask-image: -webkit-radial-gradient(#fff,#000);
    background: #fff;
  }
  
  .screen-content {
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    position: relative;
    margin: 0;
  }

  .screen-content video {
    border-radius: 16px;
    max-width: 100%;
  }
`;
  

export const BoxContainer = styled.div`

.card{
  background: #17556D;
  border-radius: 24px;
}

.card.card-has-bg {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: inherit;
}
.card.card-has-bg:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
}
.card.card-has-bg:hover {
  transform: scale(0.98);
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
  background-size: 130%;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}
.card.card-has-bg:hover .card-img-overlay {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  background: #234f6d;
  background: linear-gradient(0deg, rgba(4, 69, 114, 0.5) 0%, #044572 100%);
}

.title{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color:#fff;
}

.icon{
  font-size: 20px;
  color:#fff;
}

.sub-title{
  color: #FFFFFF;
  opacity: 0.5;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.bg{
  position: absolute;
background: url(../../images/box-card-bg.png);
background-position:bottom right;
}



`;



export const CardContainer = styled.div`

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}




@keyframes scale {
  from {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
  }
  to {
      transform: scale(1.08);
      -webkit-transform: scale(1.08);
  }
}

@keyframes slideInleft{
0% {    
  right: -10%;
}
100% {  
  right: 10%; 
} 
}



.col-3 {
  width: 25%;
}

.col-4{
  width: 33.333333%;
}

.col-2{
   width: 16.666667%;
}


.col-8{
  width:  66.666667%;
}

.col-6 {
  width: 50%;

}
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  
}

.hero .container{
  position: relative;
}


.left-side,
.right-side {
  position: relative;
}

.left-side img,
.right-side img{
 
}

.left-side img:nth-child(1) {
  position: absolute;
  right: -150%;
  top: -30%;
  height: 150px;
  object-fit: contain;
   animation: scale 1s alternate infinite;
}

.left-side img:nth-child(2) {
  position: absolute;
  right: 0;
  height: 180px;
  object-fit: contain;
  animation: scale 1.5s alternate infinite; 
}

.left-side img:nth-child(3) {
  position: absolute;
  bottom: 5%;
  right: 0;
  height: 180px;
  object-fit: contain;
    animation: scale 2s alternate infinite;
}

.right-side img:nth-child(1) {
  position: absolute;
  left: -50%;
  top: -17%;
  height:  150px;
  object-fit: contain;
   animation: scale 1.5s alternate infinite;
}

.right-side img:nth-child(2) {
  position: absolute;
      left: 30%;
  bottom: 32%;
  height: 130px;
  object-fit: contain;
 animation: scale 2s alternate infinite;
}

.right-side img:nth-child(3) {
  position: absolute;
 left: 30%;
  bottom: -10%;
  height: 180px;
  object-fit: contain;
  animation: scale 1s alternate infinite;
}

.middle-sec{
  z-index: 1;
}


.scale {
  animation: scale 2.5s alternate infinite;
  display: block;
  margin: 0 auto;
}



img.slide-card {
  position: absolute;
  height:180px;
  top: 10%;
  object-fit: contain;
  right: 0;
   animation:slideInleft 2.5s alternate infinite;
}

body{
  overflow-x: hidden;
}


/* media query*/
@media (min-width: 576px) {
  .container {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}

@media (max-width: 767px){
.col-3,.col-6,.col-4,.col-2,
.col-8,.col-6{

width: 100%;
}

.hero{
  height: auto;
}


.right-side,
.left-side{
display: flex;
justify-content: center;
}

.right-side img,
.left-side img{
  position: unset !important;
  height:105px !important;
}

.scale{
  height: 100px;
  object-fit: contain;
  margin-bottom: 30px !important;
}

img.slide-card{
  height: 100px;
  top:20%;
}


@keyframes slideInleft{
0% {    
  right: 0;
}
100% {  
  right: 30%; 
} 
}

.middle-sec{
display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.middle-sec img:nth-child(1){
  width: 85%;
}

.right-side img:nth-child(2){
height: 90px !important;
}
}


@media only screen and (min-width:768px) and (max-width:991px){
.col-6{
width: 100%;
}

@keyframes slideInleft{
0% {    
  right: 0;
}
100% {  
  right: 40%; 
}
}

.right-side img:nth-child(1){
top:-10%;
}

.left-side img:nth-child(1){
right: -110%;
  top: -12%;
  height: 120px;
}

.right-side img:nth-child(2){
left: -30%;
height: 100px;
}

.right-side img:nth-child(3){
left: -30%;
height: 150px;
}

.left-side img:nth-child(3){
right: -35%;
height: 150px;
}

.scale{
height: 130px;	
}

.left-side img:nth-child(2){
right: -15%;
  height: 140px;
}

}


@media only screen and (min-width:992px) and (max-width:1200px){

  .right-side img:nth-child(2) {
     left: -100%;
  bottom: 32%;
  height: 70px !important;
}

.right-side img:nth-child(1){
  left: -120%;
}

.left-side img:nth-child(1){
  top:-25%;
}

.right-side img:nth-child(3){
     left: -100%;
  bottom: 0;
}


.left-side img, .right-side img{
  height: 100px !important;

}


@keyframes slideInleft{
0% {    
  right: 5%;
}
100% {  
  right: 15%; 
}
}

img.slide-card {
  height: 120px;
  bottom: unset;
}

.scale{
      height: 100px;
      object-fit: cover;
}



}

@media only screen and (min-width:1201px) and (max-width:1400px){
  .middle-sec img:nth-child(1){
      height: 400px;
      object-fit: contain;
  }

  .left-side img:nth-child(1){
      top:-20%;
     
  }

  .left-side img,.right-side img{
       height: 120px !important;
  }

.right-side img:nth-child(2){
   left:-30%;
       height: 100px !important;
}

  .right-side img:nth-child(3){
    left:-30%;
  }

  .scale{
      height: 100px;
  }

  @keyframes slideInleft{
0% {    
  right: 0%;
}
100% {  
  right: 10%; 
}
}
}


@media only screen and (min-width:1401px) and (max-width:1600px){
  .middle-sec img:nth-child(1){
      height: 480px;
      object-fit: contain;
  }

  .left-side img:nth-child(1){
      right: -100%;
      top:-20%;

  }
}



@media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}

@media (min-width: 1950px){
      @keyframes slideInleft{
0% {    
  right: 0%;
}
100% {  
  right: 15%; 
}
}
}

`;