
import {useState,React,useEffect } from "react";
import { Link,withRouter,useHistory} from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
/* import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup'; */

import {HeaderContainer } from "./style";

import { Button,Nav,Navbar,Card,Container,ListGroup} from "react-bootstrap";

import MiniCart from "../mini-cart/mini-cart";
import { useAuth } from "../../providers/use-auth.js";



import { getUser,clearUser } from "../../redux/actions/authActions";
import { resetPlaceOrder } from "../../redux/actions/checkoutActions";


//import logoImg from "../../images/logo.png";

const Header = (props) => {
//class Header extends React.Component {

  const fireAuth = useAuth();
  const history = useHistory();
  //const { history } = props
  const [expanded, setExpanded] = useState(false);
  
  const dispatch = useDispatch();
 
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const isAuthenticated = useSelector((state) => state.isAuthenticated);

  const getCartCount = () => {
    return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0);
  };

  const [loggedIn, setLoggedIn] = useState(false);

  const userSelector = useSelector((state) => state.auth);
  const { auth } = userSelector;

  useEffect(() => {
    // if (auth && fireAuth.user) {
    //   setLoggedIn(true);
    //   console.log("header auth true")
    // }else{
    //   setLoggedIn(false);
    //   console.log("header auth false")
    // }

    if(auth){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }

  }, [auth]);


  const pathname = props.location.pathname;


  function toggleMenu(){
    setExpanded(!expanded)
  }

  function closeMenu(){
    setExpanded(false)
  }

   function openMenu(){
    setExpanded(true)
  }

  function openLink(path,state){
    closeMenu();
    history.push({ pathname:path, state: state });
  }


  //function logout(){
const logout = async (e) => { 
      e.preventDefault();
      await fireAuth.signout();
      localStorage.removeItem('auth');
      dispatch(clearUser());
      dispatch(resetPlaceOrder());
      history.push('/login');
  }

  return (

    <HeaderContainer>
    <Navbar sticky="top"  expand="lg" className="main-nav px-2 px-sm-0 py-2 py-lg-0" expanded={expanded} onToggle={(e)=>toggleMenu()}>
      <Container>


      <Navbar.Brand href="/"><img src={"https://dashboard.tap1ce.uk/media/logo.png"} alt="logo"></img></Navbar.Brand>

      <Navbar.Toggle aria-controls="navbarNav" />

      <Navbar.Collapse id="navbarNav">

      <Nav className="ml-auto" activeKey="home">

        <Nav.Item as="li" className={pathname == '/' ? "active":""}>
          <Nav.Link active={pathname == '/home'} eventKey="home" onClick={(e)=>openLink(`/home`)}>Home</Nav.Link>
        </Nav.Item>

        <Nav.Item as="li" className={pathname.startsWith('/how-to-use')? "active":""}>
            <Nav.Link active={pathname.startsWith('/how-to-use')} eventKey="how-to-use" onClick={(e)=>openLink(`/how-to-use`)}>How To Use</Nav.Link>
          </Nav.Item>

        
          <Nav.Item as="li" className={pathname.startsWith('/supported-phones')? "active":""}>
            <Nav.Link active={pathname.startsWith('/supported-phones')} eventKey="supported-phones" onClick={(e)=>openLink(`/supported-phones`)}>Supported Device</Nav.Link>
          </Nav.Item>

        
          <Nav.Item as="li" className={pathname.startsWith('/products')? "active":""}>
            <Nav.Link active={pathname.startsWith('/product')} eventKey="products" onClick={(e)=>openLink(`/products`)}>Products</Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" className={pathname.startsWith('/franchise')? "active":""}>
            <Nav.Link active={pathname.startsWith('/franchise')} eventKey="franchise" onClick={(e)=>openLink(`/franchise`)}>Franchise</Nav.Link>
          </Nav.Item>


          <Nav.Item as="li" className={pathname.startsWith('/my-account')? "active":""}>
            <Nav.Link active={pathname.startsWith('/my-account')} eventKey="my-account" onClick={(e)=>openLink(`/my-account`)}>My Account</Nav.Link>
          </Nav.Item>

          {/* <Nav.Item as="li" className={pathname.startsWith('/cart')? "active":""}>
            <Nav.Link active={pathname.startsWith('/cart')} eventKey="cart" onClick={(e)=>openLink(`/cart`)} id="cart"><i className="fa fa-shopping-cart dropdown-toggle"></i> Cart <span className="badge">{getCartCount()}</span></Nav.Link>  
          
          </Nav.Item> */}

{loggedIn?( <Nav.Item as="li" >
            <Nav.Link onClick={logout}>Logout</Nav.Link>
          </Nav.Item>):<></>}
      
         
      </Nav>

    </Navbar.Collapse>

    </Container>
</Navbar>

</HeaderContainer>

  );
};

//export default Header;
export default withRouter(Header) 
